export default [
  {
    header: 'Configurations',
  },
  {
    title: 'Farm Produce',
    route: 'farm-produce',
    icon: 'GridIcon',
  },
  {
    title: 'Live Stock Category',
    route: 'live-stock-type',
    icon: 'LayersIcon',
  },
  {
    title: 'Stock Item Category',
    route: 'farm-stock',
    icon: 'ServerIcon',
  },
  {
    title: 'Stock Item Units',
    route: 'item-units',
    icon: 'SlidersIcon',
  },
  {
    title: 'Income Types',
    route: 'income-types',
    icon: 'BookIcon',
  },
  {
    title: 'Expense Types',
    route: 'expense-types',
    icon: 'CreditCardIcon',
  },
  // {
  //   title: 'User Accounts',
  //   route: 'user-accounts',
  //   icon: 'UsersIcon',
  // },
]
