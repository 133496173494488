export default [
  {
    header: 'Produce',
  },
  {
    title: 'Manage',
    route: 'register-produce',
    icon: 'FileTextIcon',
  },
  {
    title: 'Track',
    route: 'track-produce',
    icon: 'TrendingUpIcon',
  },
  // TODO: In second Version
  // {
  //   title: 'History',
  //   route: 'produce-history',
  //   icon: 'FilePlusIcon',
  // },
]
