export default [
  {
    header: 'LiveStock & Poultry',
  },
  // {
  //   title: 'Dashboard',
  //   route: 'livestock-dashboard',
  //   icon: 'FileTextIcon',
  // },
  {
    title: 'Manage',
    route: 'manage-livestock-and-poultry',
    icon: 'ListIcon',
  },
  // {
  //   title: 'Track',
  //   route: 'track-livestock',
  //   icon: 'FilePlusIcon',
  // },
  // {
  //   title: 'History',
  //   route: 'livestock-history',
  //   icon: 'FilePlusIcon',
  // },
]
