export default [
  {
    header: 'Expenses',
  },
  {
    title: 'Register Expenses',
    route: 'expenses',
    icon: 'Maximize2Icon',
  },
]
