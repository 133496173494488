export default [
  {
    header: 'Inventory',
  },
  {
    title: 'Inventory',
    route: 'stock',
    icon: 'SlidersIcon',
  },
  {
    title: 'StockIn',
    route: 'inventory-stockin',
    icon: 'CornerDownRightIcon',
  },
  {
    title: 'StockOut',
    route: 'inventory-stockout',
    icon: 'CornerDownLeftIcon',
  },
]
