export default [
  {
    header: 'Income',
  },
  {
    title: 'Register Income',
    route: 'income',
    icon: 'MinimizeIcon',
  },
]
